import semver, { ReleaseType } from 'semver';

type UpdateTypesKeys = 'revision' | 'minor' | 'major' | 'documentation' | '';
interface UpdateTypes {
  revision: ReleaseType;
  minor: ReleaseType;
  major: ReleaseType;
  documentation: ReleaseType;
}

const updateTypes: UpdateTypes = {
  'revision': 'patch',
  'minor': 'minor',
  'major': 'major',
  'documentation': 'patch',
};


function capitalizeFirstLetter(string: string) {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}

/**
 * 
 * @param currentVersion The current version of the pack.
 * @param updateType The Update type of the change being made.
 * @returns 
 */
function bumpPackVersion(currentVersion: string | undefined, updateType: UpdateTypesKeys) {
  
  if (currentVersion === undefined) {
    console.log('bumpPackVersion: No current version was supplied.')
    return ''
  }

  if (updateType === "") {
    console.log('updateType is empty. Version was not bumped.');
    return currentVersion;
  }

  const releaseType: ReleaseType = updateTypes[updateType];
  const bumpedVersion: string = semver.inc(currentVersion, releaseType) || "";
  const parsedBumpedVersion: string[] = bumpedVersion.split(".");

  for (const val of parsedBumpedVersion) {
    if (parseInt(val) > 99) {
      console.log('bumpPackVersion: Version number is greater than 99. Version was not bumped.')
      return currentVersion
    }
  }

  return bumpedVersion;
}

/**
 * 
 * @param contentItemsList Map of the modified content items in a pack per content item type (Scripts, Layouts, Integration etc..)
 * @param updateType The type of update being done. When 'documentation' a predefined text will be written.
 * @returns Generates release notes template for the detected changes.
 */
function createRNTemplate(contentItemsList: Record<string, string[]> | undefined, updateType = ''){
  let releaseNote = ''
  let updateTemplate = '- %%UPDATE_RN%%\n\n'
  if (updateType === 'documentation'){
    updateTemplate = "- Documentation and metadata improvements.\n\n"
  } 
  if (contentItemsList === undefined || Object.keys(contentItemsList).length === 0){
    releaseNote = ''
  }
  else{
    Object.entries(contentItemsList).forEach(([contentItemType, contentItem]) => {
      releaseNote = releaseNote + `#### ${capitalizeFirstLetter(contentItemType)}\n`


      if (contentItem instanceof Array) {
        contentItem.forEach((item: string) => { releaseNote = `${releaseNote}##### ${item}\n${updateTemplate}` });
      } else {
        releaseNote = `${releaseNote}##### ${contentItem as string}\n${updateTemplate}`
      }
    })
  }
  return releaseNote
}

/**
 * 
 * @param rnString The release note user input.
 * @returns An error message if the user input contains the template text,. 
 */
 function validateRNInput(rnString: string) {
  
  const update_template = '%%UPDATE_RN%%'

  if (rnString.indexOf(update_template) !== -1) {
    return "Please finish filling out the release notes and replace the %%UPDATE_RN%% template text with informative data."
  }
 }


export {bumpPackVersion, createRNTemplate, validateRNInput, UpdateTypesKeys};
