import React from 'react';
import TextField from '@mui/material/TextField';
import { MenuItem, SelectProps } from '@mui/material';


const helperText = "There were content item(s) that were detected as versions of content from pre-existing packs. If it was your intent to update an existing content pack, select \"Update Existing Pack\", otherwise select \"Create New Pack\".";

export type onSelectMode = () => void;

export interface ModeSelectProps {
    on_select_mode: onSelectMode,
}


const ModeSelect: React.FC<SelectProps & ModeSelectProps> = ({ on_select_mode }) => {
    const [mode, setMode] = React.useState("new");
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value as string !== mode) {
            on_select_mode();
        }
        setMode(event.target.value as string);
    };

    return (
        <TextField
            id="simple-select-mode-label"
            select
            required
            label="Contribution Mode"
            value={mode}
            onChange={handleChange}
            helperText={helperText}
            variant="outlined"
        >
            <MenuItem value="" disabled>
                <em>Select Contribution Mode</em>
            </MenuItem>
            <MenuItem value={"new"}>Create New Pack</MenuItem>
            <MenuItem value={"existing"}>Update Existing Pack</MenuItem>
        </TextField>
    );
}

export default ModeSelect;
