import {
  Collapse,
  Grid,
  InputAdornment,
  LinearProgress,
  Link,
  TextField as MuiTextField,
} from '@mui/material';
import { GitHub, Storefront } from '@mui/icons-material';
import { Alert, AlertTitle } from '@mui/material';
import { WindowLocation } from '@reach/router';
import axios, { AxiosError } from 'axios';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import React, { useEffect, useRef } from 'react';
import * as yup from 'yup';
import {UPDATE_URL, STATUS_INITIAL, STATUS_INITIAL_RESUBMISSION} from '../constants';
import CLA from './CLA';
import GitHubLoginButton from './GithubLoginButton';
import ModeSelect from './ModeSelect';
import UpdateTypeSelect from './UpdateTypeSelect';
import PackSelect, { PackInfoType } from './PackSelect';
import { bumpPackVersion, createRNTemplate, validateRNInput, UpdateTypesKeys } from '../utils';
import { PaperStyle, AvatarStyle, FormStyle, ClaBoxStyle, ButtonStyle } from '../styles';

const messages = {
  submitButton: 'Submit for Human Review',
  resubmitButton: 'Resubmit for Human Review'
};

interface ServerResponse {
  data: ServerData
}

interface ServerData {
  status: string,
}

const updateSchema = yup.object().shape({
  name: yup.string().required('Pack Name is required.'),
  github_user: yup.string().required('Please Sign In with a GitHub user.'),
  github_token: yup.string(),
  slack_user: yup.string(),
  description: yup.string(),
  notes: yup.string(),
  cla_accept: yup.bool().oneOf([true], 'You must accept the Contributor License Agreement.'),
  video_link: yup.string(),
  author: yup.string(),
  use_existing_pack: yup.bool(),
  update_type: yup
    .string()
    .when(
      'use_existing_pack', {
        is: true,
        then: yup.string().required('You must select the type of update being done.')
      },
    ),
  release_notes: yup.string()
});

export type SetStatusCB = (status: string) => void;

async function postForm(pack_id: string, set_status_cb: SetStatusCB, values: { [id: string]: string | boolean | string[] | Record<string, string[]> | null | undefined }) {
  const formData = new URLSearchParams();
  formData.append('id', pack_id);

  for (const [key, value] of Object.entries(values)) {
    if (value) {
      formData.append(key, value.toString())
    }
  }
  console.log('uploading: ', formData);
  const res: ServerResponse = await axios.post(UPDATE_URL, formData);
  console.log('axios res: ', res);
  set_status_cb(res.data.status)
}

export interface UpdateFormProps {
  pack_id: string,
  set_status_cb: SetStatusCB,
  location: WindowLocation,
  pack_name?: string,
  pack_description?: string,
  pack_author?: string,
  slack_user?: string,
  video_link?: string,
  notes?: string,
  status?: string,
  use_existing_pack?: boolean,
  source_packs_details?: PackInfoType[],
  all_packs_details?: PackInfoType[],
  contribution_items_list?: Record<string, string[]>,
}

/**
 *
 * @param packOptions Array of objects containing details about each pack.
 * @param pack_name Name of the pack to get details on.
 * @returns Gets the pack details for a given pack name.
 */
function getExistingPackDetails(packOptions: PackInfoType[] | undefined, pack_name: string | undefined) {
  if (packOptions) {
    for (const pack_details of packOptions) {
      if (pack_details.pack_name === pack_name) {
        return pack_details
      }
    }
  }
  console.log('Could not find existing pack details for pack name: ', pack_name);
  return undefined
}

/**
 *
 * @param status Submission status.
 * @param use_existing_pack Whether the contribution is to an existing pack or not.
 * @param packOptions Array of objects containing details about each pack.
 * @param pack_name Name of the pack to get details on.
 * @param sourcePackOptions Array of objects containing details about suggested pack.
 * @returns Initializes the selected pack in the form.
 */
function initSelectedPack(status: string | undefined,
                          use_existing_pack: boolean | undefined,
                          packOptions: PackInfoType[] | undefined,
                          pack_name: string | undefined,
                          sourcePackOptions: PackInfoType[] | undefined) {
    // initializes the selected pack in the form.

    if (status === STATUS_INITIAL_RESUBMISSION && use_existing_pack) {
      return getExistingPackDetails(packOptions, pack_name)
    }
    else if (sourcePackOptions && !!sourcePackOptions.length) {
      return sourcePackOptions[0]
    }
    else if (packOptions && !!packOptions.length) {
      return packOptions[0]
    }
    return undefined
}

const UpdateForm: React.FC<UpdateFormProps> = ({ pack_id,
  set_status_cb, pack_name, pack_description, pack_author, slack_user, location, status, source_packs_details, all_packs_details, contribution_items_list, use_existing_pack }) => {
  const [errorMsg, setErrorMsg] = React.useState<string | undefined>(undefined);
  const [editExisting, setEditExisting] = React.useState<boolean | undefined>(false);
  const packOptions = all_packs_details
  const sourcePackOptions = source_packs_details
  const contributionItemsList = contribution_items_list
  const [selectedPack, selectPack] = React.useState<PackInfoType | undefined>(() => initSelectedPack(status, use_existing_pack, packOptions, pack_name, sourcePackOptions));
  const [updateType, setUpdateType] = React.useState<UpdateTypesKeys>('');
  const [packBumpedVersion, setNewVersion] = React.useState("");

  // Calculates the bumpedVersion on an updateType change.
  useEffect(
    () => {
      if (updateType!=="") {
        setNewVersion(bumpPackVersion(selectedPack?.pack_current_version, updateType));
      }
    },
    [updateType, selectedPack],
  );

  // Resets the updateType and the bumpedVersion on a selectedPack change.
  useEffect(
    () => {
      setNewVersion("");
      setUpdateType("");
    },
    [selectedPack],
  );

  console.log('selectedPack: ', selectedPack);

  const errorRef = useRef<HTMLDivElement>(null);
  return (
    <Formik initialValues={
      {
        name: pack_name || '',
        description: pack_description || '',
        existing_pack_name: (selectedPack?.pack_name || ''),
        existing_pack_author: (selectedPack?.pack_author || ''),
        existing_pack_description: (selectedPack?.pack_description || ''),
        existing_pack_dir_name: (selectedPack?.pack_dir_name || ''),
        existing_pack_version: (selectedPack?.pack_current_version || ''),
        detected_content_items: (selectedPack?.detected_content_items || ''),
        release_notes: createRNTemplate(contributionItemsList),
        notes: '',
        cla_accept: false,
        video_link: '',
        author: pack_author || '',
        github_user: '',
        slack_user: slack_user || '',
        use_existing_pack: use_existing_pack,
      }
    }
      validateOnBlur={false} validateOnChange={false}
      validationSchema={updateSchema}
      onSubmit={(values, { setSubmitting }) => {
        postForm(pack_id, set_status_cb, values)
          .catch((e: AxiosError) => {
            console.log('Failed upload: ', e, e.response);
            let msg: string;
            if (axios.isAxiosError(e) && e.response) {
              msg = `${JSON.stringify(e.response.data)} (${String(e.response.status)})`
            }
            else {
              msg = e.message
            }
            setErrorMsg(msg);
            setSubmitting(false)
          })
      }}
    >
      {(props) => {
        return (
          <PaperStyle ref={errorRef} maxWidth="md">
            <AvatarStyle>
              <Storefront />
            </AvatarStyle>
            <FormStyle noValidate>
              <Grid container spacing={2}>
              <>
                <Grid item xs={12}>
                  <Collapse in={Object.keys(props.errors).length !== 0 || errorMsg !== undefined} onEntered={() => window.scrollTo(0, errorRef?.current?.offsetTop || 0)}>
                    <Alert severity='error' onClose={() => {setErrorMsg(undefined); props.setErrors({}) }}>
                      <AlertTitle>Error</AlertTitle>
                      {Object.values(props.errors).map(msg => (<li>{msg}</li>))}
                      {errorMsg}                        
                    </Alert>
                  </Collapse>
                </Grid>
                {status === STATUS_INITIAL &&
                  <Grid item xs={12}>
                    <Collapse in={packOptions && !!packOptions.length} onEntered={() => window.scrollTo(0, 0)}>
                      <ModeSelect
                        on_select_mode={
                          () => {
                            setEditExisting((prev) => !prev);
                            props.setFieldValue("use_existing_pack", !props.values.use_existing_pack);
                          }
                        }
                      />
                    </Collapse>
                  </Grid>
                }
                {
                  status === STATUS_INITIAL_RESUBMISSION && setEditExisting(props.values.use_existing_pack)
                }
                <Grid item xs={12}>
                  {
                    (editExisting) ? (
                      <PackSelect
                        selectedPack={selectedPack}
                        status={status}
                        suggestedOptions={sourcePackOptions}
                        onPackSelect={
                          (selected_pack: PackInfoType | undefined) => {
                            selectPack(selected_pack);
                            props.setFieldValue("existing_pack_name", selected_pack?.pack_name || '');
                            props.setFieldValue("existing_pack_author", selected_pack?.pack_author || '');
                            props.setFieldValue("existing_pack_description", selected_pack?.pack_description || '');
                            props.setFieldValue("existing_pack_dir_name", selected_pack?.pack_dir_name || '');
                            props.setFieldValue("existing_pack_version", selected_pack?.pack_current_version || '');
                            props.setFieldValue("release_notes", createRNTemplate(contributionItemsList, updateType));
                          }
                        }
                        packOptions={packOptions}
                      />
                    ) : (
                        <Field variant="outlined" component={TextField}
                          name="name" label="Pack Name" fullWidth required
                          disabled={status !== STATUS_INITIAL}
                          helperText='Name the Pack as it will be displayed in the Marketplace. For example: Brute Force Investigation.' />
                      )
                  }
                </Grid>
                {(editExisting) &&
                  <Grid item xs={12}>
                    <UpdateTypeSelect
                      updateType={updateType}
                      packBumpedVersion={packBumpedVersion}
                      onUpdateTypeSelect={(update_type: UpdateTypesKeys) => {
                        setUpdateType(update_type);
                        if (update_type === 'documentation' || updateType === 'documentation') {
                          props.setFieldValue("release_notes", createRNTemplate(contributionItemsList, update_type));
                        }
                        props.setFieldValue("update_type", update_type);
                      }}

                    />
                  </Grid>
                }
                {(editExisting) && (
                  <Grid item xs={12}>
                    <Field variant="outlined" component={TextField}
                      name={(editExisting && !!props.values.existing_pack_description) ? "release_notes" : ''}
                      validate={validateRNInput}
                      label="Release Notes"
                      required
                      fullWidth multiline rows={7}
                      helperText={<span>Release notes to keep track of the changes made for the Content Pack. For detailed information and examples see&nbsp;
                      <Link href="https://xsoar.pan.dev/docs/documentation/release-notes#examples-and-best-practices" target="_blank">Pack Release Notes</Link>.</span>}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MuiTextField variant="outlined" name="github_user" label="GitHub User" fullWidth required
                    helperText="We require signing in with GitHub so you can comment and take part in the Pull Request review process."
                    value={props.values.github_user || "Please Sign In"}
                    error={props.errors.github_user !== undefined}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">
                        <GitHubLoginButton startIcon={<GitHub />} location={location}
                          on_login={(user, token) => {
                            props.setFieldValue('github_user', user)
                            props.setFieldValue('github_token', token)
                          }}
                          variant="contained" color='primary' >Sign In with Github</GitHubLoginButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={TextField} name="slack_user" label="Slack User" fullWidth
                    helperText={
                      <span>Enables the XSOAR team to contact you directly via Slack DFIR Community.&nbsp;
                             <Link href="https://start.paloaltonetworks.com/join-our-slack-community" target="_blank"
                          rel="noopener noreferrer">
                          Not a member yet? Join the Slack DFIR Open Community
                             </Link>
                      </span>} />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={TextField}
                    name={(editExisting && !!props.values.existing_pack_author) ? "existing_pack_author" : "author"}
                    label="Pack Author"
                    fullWidth
                    disabled={(editExisting && !!props.values.existing_pack_author)}
                    helperText="The Author's name as it will be displayed in the Marketplace. If left blank, Pack will be displayed with the Github user name." />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={TextField}
                    name={(editExisting && !!props.values.existing_pack_description) ? "existing_pack_description" : "description"}
                    label="Pack Description"
                    disabled={(editExisting && !!props.values.existing_pack_description)}
                    fullWidth multiline rows={4}
                    helperText='A short description of the Content Pack as it will be displayed in the Marketplace. Should provide a high level overview of the Content Pack content.' />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={TextField} name="notes" label="Notes to reviewer" fullWidth multiline rows={4}
                    helperText="Provide additional info that will help us review your Content Pack. This data will not be displayed in the Marketplace." />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={TextField} name="video_link" label="Demo Video Link" fullWidth
                    helperText="Short demo video of the Content Pack usage. Speeds up the review. Optional but recommended. Use a video sharing service such as Google Drive or YouTube." />
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <ClaBoxStyle item xs={12}>
                  <CLA />
                </ClaBoxStyle>
                <Grid item xs={12}>
                  <hr />
                  <Field component={CheckboxWithLabel} name="cla_accept" type="checkbox"
                    Label={{ label: "I have read and accept the terms in the Contributor License Agreement above." }} />
                </Grid>
                <Grid item xs={12}>
                  <p>We appreciate your contribution to our open source code repository. By signing the contributor license agreement, we ensure that the community is free to use your contributions.
                    The CLA document is also available for review as a <Link href="https://github.com/demisto/content/blob/master/docs/cla.pdf" target="_blank" rel="noopener noreferrer">PDF</Link>.</p>
                  <p>The information you provide, along with your IP address and a timestamp, will
                  be maintained in accordance with Palo Alto Networks’ &nbsp;
                  <Link href="https://www.paloaltonetworks.com/legal-notices/privacy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.</p>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  {props.isSubmitting && <LinearProgress />}
                  {/* Formik is expecting a type="submit" prop in the button component in order to trigger
                    the onSubmit function prop. see example: https://formik.org/docs/guides/typescript */}
                  <ButtonStyle variant="contained" color='primary'
                    disabled={props.isSubmitting} type="submit">{status === STATUS_INITIAL ? messages.submitButton : messages.resubmitButton}
                  </ButtonStyle>
                </Grid>
                </>
              </Grid>
            </FormStyle >
          </PaperStyle>
        );
      }}
    </Formik >
  );
}

export default UpdateForm;
