import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { MenuItem, SelectProps } from '@mui/material';

const baseHelperText = (
    <span>
      The type of update being done. See{' '}
      <a
        href="https://xsoar.pan.dev/docs/contributing/marketplace#submit-a-content-pack"
        target="_blank"
        rel="noreferrer"
      >
        Contributing Through Cortex XSOAR Marketplace
      </a>{' '}
      for more information about each option.
    </span>
  );

export interface UpdateTypeSelectProps {
    onUpdateTypeSelect: CallableFunction,
    updateType: string
    packBumpedVersion: string
}

const UpdateTypeSelect: React.FC<SelectProps & UpdateTypeSelectProps> = ({ updateType, onUpdateTypeSelect , packBumpedVersion}) => {
    const [helperText, setHelperText] = useState(baseHelperText);

    useEffect(
        () => {
            if (!packBumpedVersion) {
                setHelperText(baseHelperText)
            }
            else {
                setHelperText(<p>The new version is + {packBumpedVersion}.</p>)
            }
        },
        [packBumpedVersion],
      );    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            onUpdateTypeSelect(event.target.value);
        }

    };

    return (
        <TextField
            id="simple-select-update-type-label"
            select
            required
            label="Update Type"
            value={updateType}
            onChange={handleChange}
            helperText={helperText}
            fullWidth
            variant="outlined"
        >
            <MenuItem value="revision" disabled>
                <em>Select Update Type</em>
            </MenuItem>
            <MenuItem value={"revision"}>Revision</MenuItem>
            <MenuItem value={"minor"}>Minor</MenuItem>
            <MenuItem value={"major"}>Major</MenuItem>
            <MenuItem value={"documentation"}>Documentation</MenuItem>
        </TextField>
    );
}

export default UpdateTypeSelect;
