import { CircularProgress, Link, Typography } from '@mui/material';
import { AlertTitle } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import { STATUS_URL, STATUS_INITIAL, STATUS_GIT_RESUBMITTED, STATUS_GIT_SUBMITTED, STATUS_INITIAL_RESUBMISSION, STATUS_PR_CREATED, STATUS_PR_UPDATED } from '../constants';
import UpdateForm from '../components/UpdateForm';
import { PackInfoType } from '../components/PackSelect';
import { ContainerStyle, AlertStyle } from '../styles';

const messages = {
  pageTitle: 'Contribution Pack Status',
  pageTitleResubmit: 'Contribution Pack Status - Resubmit',
  pageSubtitleSubmitting: 'Submitting...',
  pageSubtitleResubmitting: 'Resubmitting...',
  pageSubtitleSubmitted: 'Submitted Successfully',
  pageSubtitleResubmitted: 'Resubmitted Successfully',
};

export interface ExtantPackData {
  detected_content_items: Record<string, string[]>,
  pack_name: string,
  pack_author: string,
  pack_description: string
}

interface StatusState {
  status?: string,
  error_msg?: string,
  pack_name?: string,
  pack_description?: string,
  pr_link?: string,
  source_code_link?: string,
  pack_author?: string,
  slack_user?: string,
  source_packs_details?: PackInfoType[],
  use_existing_pack?: boolean,
  all_packs_details?: PackInfoType[],
  contribution_items_list?: Record<string, string[]>,
}

interface ServerResponse {
  data: ServerData,
  status: number,
}

interface ServerData {
  status: string,
  name: string,
  description: string,
  source_packs: sourcePacks,
  all_packs_data: Record<string, Record<string, string>>,
  author: string,
  slack_user: string,
  pr_link: string,
  source_code_link: string,
  use_existing_pack: boolean,
  currentVersion: string,
  contribution_items_list: Record<string, string[]>,
}

function getPackDetails(pack_dir_name: string, pack_details: Record<string, string>) {
  const pack_info: PackInfoType = {
    pack_dir_name: pack_dir_name,
    pack_name: pack_dir_name,
    pack_author: '',
    pack_description: '',
    pack_current_version: ''
  };
  try {
    pack_info.pack_name = pack_details.name;
    pack_info.pack_author = pack_details.author;
    pack_info.pack_description = pack_details.description;
    pack_info.pack_current_version = pack_details.currentVersion;
  } catch (error) {
    console.log(`error assigning pack info for "${pack_dir_name}"`);
    console.log(error);
  }
  return pack_info;
}

interface sourcePacks {
  [key: string]: Record<string, string[]>;
}

function packsToDetailedPackOptions(packs?: Record<string, Record<string, string>>,
                                          sourcePacks_details?: sourcePacks) {
  let mapPackInfoOptions = [] as PackInfoType[];
  if (packs === undefined || !Object.keys(packs).length) {
    console.log('failed creating packOptions');
    return mapPackInfoOptions;
  }
  else {
    if (sourcePacks_details){
      const source_packs_dir_names = Object.keys(sourcePacks_details);
      mapPackInfoOptions = source_packs_dir_names.map((source_pack_dir) => {
        const pack_info = getPackDetails(source_pack_dir, packs[source_pack_dir]);
        // packs can be either Array or a dict, therefore eslint raise an error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        pack_info.detected_content_items = sourcePacks_details[source_pack_dir]
        return pack_info;
      });
    }
    else {
      mapPackInfoOptions = Object.entries(packs).map(([pack_name, pack_details]) => {
        const pack_info = getPackDetails(pack_name, pack_details);
        pack_info.detected_content_items = {}
        return pack_info;
      });
    }
    return mapPackInfoOptions;
  }
}

const FOLLOW_EMAIL_LINK = "Please follow the status link sent via email to check the status of your contribution."

const StatusPage: React.FC<PageProps> = ({ location }) => {

  const [state_status, set_state_status] = useState<StatusState>({});

  const pack_id: string = queryString.parse(location.search).id as string;

  const fetch_status = async () => {
    if (!pack_id) {
      set_state_status({
        error_msg: "Contribution ID not specified. " + FOLLOW_EMAIL_LINK
      })
      return
    }
    try {
      const res: ServerResponse = await axios.get(STATUS_URL + `?id=${pack_id}`)
      console.log('axios res: ', res)
      if (!res.data.status) {
        set_state_status({
          error_msg: "Bad contribution status returned. HTTP status: " + String(res.status)
        })
      }
      else if (res.data.status === STATUS_INITIAL || res.data.status === STATUS_GIT_SUBMITTED) {
        const all_packs_details = packsToDetailedPackOptions(res.data.all_packs_data);
        const source_packs_details = packsToDetailedPackOptions(res.data.all_packs_data, res.data.source_packs);
        set_state_status({
          status: res.data.status,
          pack_name: res.data.name,
          pack_description: res.data.description,
          source_packs_details: source_packs_details,
          all_packs_details: all_packs_details,
          contribution_items_list: res.data.contribution_items_list,
        })
      }
      else {
        // res.data.status will be STATUS_PR_CREATED, STATUS_INITIAL_RESUBMISSION, 'GIT_RESUBMITTED' OR STATUS_PR_UPDATED
        const all_packs_details = packsToDetailedPackOptions(res.data.all_packs_data);
        const source_packs_details = packsToDetailedPackOptions(res.data.all_packs_data, res.data.source_packs);
        set_state_status({
          status: res.data.status,
          pack_name: res.data.name,
          pack_description: res.data.description,
          pack_author: res.data.author,
          slack_user: res.data.slack_user,
          pr_link: res.data.pr_link,
          source_code_link: res.data.source_code_link,
          use_existing_pack: res.data.use_existing_pack,
          source_packs_details: source_packs_details,
          all_packs_details: all_packs_details,
          contribution_items_list: res.data.contribution_items_list,
        })
      }
    } catch (e) {
      const err = e as Error | AxiosError;
      let msg: string;
      if (axios.isAxiosError(err) && err.response) {
        msg = `${JSON.stringify(err.response.data)} (${String(err.response.status)})`
        if (err.response.status === 404) {
          msg += " " + FOLLOW_EMAIL_LINK
        }
      }
      else {
        msg = err.message
      }
      set_state_status({
        error_msg: msg
      })
    }
  }

  useEffect(() => {
    if (!state_status.error_msg && !state_status.status) {
      void fetch_status()
    }
  });

  return (
    <Page title={state_status.status === STATUS_INITIAL_RESUBMISSION ? messages.pageTitleResubmit : messages.pageTitle} heading="h2">
      {!state_status.status &&
        <ContainerStyle >
          {!state_status.error_msg ?
            <CircularProgress /> :
            <AlertStyle severity='error'>
              <AlertTitle>Error</AlertTitle>
              {state_status.error_msg}
            </AlertStyle>
          }
        </ContainerStyle>
      }
      {!state_status.error_msg && state_status.status && [STATUS_INITIAL, STATUS_INITIAL_RESUBMISSION].indexOf(state_status.status) > -1 &&
        <React.Fragment>
          {state_status.status === STATUS_INITIAL ?
            <Typography variant="body1" gutterBottom>
              Thank you for your contribution! We just need a few more details.<br /><br />
              All submissions are manually reviewed by our team members. Once submitted, we will start a review process
              and may contact you if more information is needed.
              When our review is done, your contribution will be published as a
              Content Pack on the <Link href="https://xsoar.pan.dev/marketplace" target="_blank">Cortex XSOAR Marketplace</Link>.
            </Typography> :
            <Typography variant="body1" gutterBottom>
              <b>Note:</b> You are about to resubmit a pack which will modify
              an existing <Link href={state_status.pr_link} target="_blank">Pull Request</Link> that
              is currently under manual review by our team.
              This will modify the files in the Pull Request, possibly overwriting changes applied in
              the Pull Request as part of the review process. Please ensure that this is in-fact your intention.
            </Typography>}
        <UpdateForm location={location} pack_id={pack_id} pack_name={state_status.pack_name}
                    pack_description={state_status.pack_description}
                    set_status_cb={(s: string) => set_state_status({status: s})}
                    status={state_status.status}
                    pack_author={state_status.pack_author}
                    slack_user={state_status.slack_user}
                    source_packs_details={state_status.source_packs_details as PackInfoType[]}
                    use_existing_pack={state_status.use_existing_pack}
                    all_packs_details={state_status.all_packs_details}
                    contribution_items_list={state_status.contribution_items_list} />
      </React.Fragment>
      }
      {!state_status.error_msg && state_status.status && [STATUS_GIT_SUBMITTED, STATUS_GIT_RESUBMITTED].indexOf(state_status.status) > -1 &&
        <React.Fragment>
          <Typography variant="h4" gutterBottom>
            {state_status.status === STATUS_GIT_SUBMITTED ? messages.pageSubtitleSubmitting : messages.pageSubtitleResubmitting}
          </Typography>
          {state_status.status === STATUS_GIT_SUBMITTED ?
            <Typography variant="body1" gutterBottom>
              Thank you! Your Contribution Pack has been accepted for manual review by our team.<br></br><br />
              We are creating a Github Pull Request which will be used to review your submission.<br></br><br></br>
              Once the Pull Request is ready, you will receive an email notification.<br /><br />
            </Typography> :
            <Typography variant="body1" gutterBottom>
              Thank you! Your resubmit request has been accepted. It might take a few minutes to update it in the pull
              request.<br></br><br />
              Once the Pull Request is ready, you will receive an email notification.<br /><br />
            </Typography>
          }
        </React.Fragment>
      }
      {!state_status.error_msg && state_status.status && [STATUS_PR_CREATED, STATUS_PR_UPDATED].indexOf(state_status.status) > -1 &&
        <React.Fragment>
          <Typography variant="h4" gutterBottom>
            {state_status.status === STATUS_PR_CREATED ? messages.pageSubtitleSubmitted : messages.pageSubtitleResubmitted}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you! Your Contribution Pack has
            been {state_status.status === STATUS_PR_CREATED ? 'accepted ' : 'successfully resubmitted '}
            for manual review by our team.<br></br><br></br>
            We have {state_status.status === STATUS_PR_CREATED ? 'created a ' : 'updated the '}
            Github Pull Request which {state_status.status === STATUS_PR_CREATED ? 'will be ' : 'is '}
            used by our team to review your Content Pack.<br></br><br></br>
          </Typography>
        </React.Fragment>
      }
      {!state_status.error_msg && state_status.status && [STATUS_GIT_SUBMITTED, STATUS_INITIAL, STATUS_INITIAL_RESUBMISSION].indexOf(state_status.status) < 0 &&
        <React.Fragment>
          <Typography variant="body1" gutterBottom>
            Please add comments and feedback as necessary to the Pull Request. Use the following links:<br />
            <Link href={state_status.pr_link} target="_blank">Pull Request</Link><br />
            <Link href={state_status.source_code_link} target="_blank">Content Pack Source Code</Link><br /><br />
            To resubmit the content pack at this stage you need to contribute the pack again from the platform:
            <ul>
              <li>
                Go back to XSOAR platform
              </li>
              <li>
                Select the pack from the Contributions table
              </li>
              <li>
                Click "Contribute" button
              </li>
            </ul>
          </Typography>
        </React.Fragment>
      }
    </Page>
  );
}

export default StatusPage;
