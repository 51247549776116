import React from 'react';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { STATUS_INITIAL_RESUBMISSION } from "../constants";

export interface PackInfoType {
    inputValue?: string;
    pack_name: string;
    pack_author?: string;
    pack_description?: string;
    pack_dir_name?: string;
    detected_content_items?: Record<string, string[]>;
    pack_current_version?: string;
}

export interface PackSelectProps {
    selectedPack: PackInfoType | undefined,
    onPackSelect: CallableFunction,
    packOptions: PackInfoType[] | undefined,
    suggestedOptions: PackInfoType[] | undefined,
    status: string | undefined,
}
const modifyHelp = 'The pack whose content you wish to modify or update.\nSuggested packs will appear at the top of the list.';
const PackSelect: React.FC<PackSelectProps> = (props) => {
    const handleChange = (event: { target: {value: unknown }}) => {
        if (event.target.value) {
            props.onPackSelect(event.target.value);
        }
    };
    const sortIt = sortBy => (a: PackInfoType, b: PackInfoType) => {
        if (a.pack_name.toLowerCase() > b.pack_name.toLowerCase()) {
          return 1;
        } else if (a.pack_name.toLowerCase() < b.pack_name.toLowerCase()) {
          return -1;
        }
        return 0;
      }
    if (props.packOptions){
        props.packOptions.sort(sortIt('pack_name'));
    }

    return (
        <TextField
            id="simple-select-pack-label"
            select
            required
            label="Select Existing Pack"
            value={props.selectedPack}
            onChange={handleChange}
            fullWidth
            helperText={modifyHelp}
            variant="outlined"
            disabled={props.status === STATUS_INITIAL_RESUBMISSION}
        >
            {props.suggestedOptions && !!props.suggestedOptions.length && (

            <MenuItem value="SuggestedPacks" disabled>
                <em>Suggested packs</em>
            </MenuItem>)}
            {/* https://github.com/mui/material-ui/issues/14286 */}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props.suggestedOptions && props.suggestedOptions.map((option: any) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                <MenuItem key={option.pack_name} value={option}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    {option.pack_name}
                </MenuItem>
            ))}
            <MenuItem value="AllPacks" disabled>
                <em>All packs</em>
            </MenuItem>
            {/* https://github.com/mui/material-ui/issues/14286 */}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {props.packOptions && props.packOptions.map((option: any) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                <MenuItem key={option.pack_name} value={option}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                    {option.pack_name}
                </MenuItem>
            ))}
        </TextField>
    );
}

export default PackSelect;
